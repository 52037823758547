<template>
	<div>
		<div class="head">
			<h4 class="head-label">我的AI次数</h4>
			<div class="head-item">
				<div class="item-title">已使用次数</div>
				<div class="item-amount">{{ formData.num1 }}</div>
			</div>
			<div class="head-item">
				<div class="item-title">剩余次数</div>
				<div class="flex">
					<span class="item-amount">{{ formData.num2 }}</span>
					<a style="margin-left: 12px;" href="javascript:;" @click="openBuyPackage()">&nbsp;去充值</a>
				</div>
			</div>
		</div>
		<div class="table-list">
			<Table :data="data" :columns="columns" :loading="loading"></Table>
		</div>
		<div class="ser-page">
			<Page style="margin-top: 10px; text-align: right;" v-model="search.page" :total="search.total"
				:page-size="search.pageSize" :page-size-opts="[10,15,20]" show-total show-sizer transfer
				@on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
		</div>
		<Modal v-model="buy_package_modal.show" title="充值流量套餐" width="70" @on-cancel="colseBuyPackage()">
			<Form :label-width="100" label-colon>
				<FormItem label="套餐选择">
					<Row :gutter="16">
						<Col :span="6" v-for="(item, index) in buy_package_modal.list" :key="index">
						<div class="item-box" :class="{'active': item.id == buy_package_modal.data.id}" @click="activePackage(item)">
							<div class="item-info">
								<span>{{ item.name }}</span>
								<span style="color: red; font-weight: bold;">{{ item.amount + '次' }}</span>
							</div>
							<div class="item-price">{{ item.price + '元' }}</div>
						</div>
						</Col>
					</Row>
				</FormItem>
			</Form>
			<template #footer>
				<div style="display: flex; justify-content: space-between;">
					<div v-if="buy_package_modal.data.id !== 0" style="font-size: 16px;">
						<span style="margin-right: 12px;">{{ buy_package_modal.data.name }}</span>
						<span style="margin-right: 12px;">{{ buy_package_modal.data.amount + '次' }}</span>
						<span>{{ buy_package_modal.data.price + '元' }}</span>
					</div>
					<div v-else></div>
					<div>
						<Button @click="colseBuyPackage()">取消</Button>
						<Button type="primary" @click="confirmBuyPackage()">确定</Button>
					</div>
				</div>
			</template>
		</Modal>
		<!-- 微信支付二维码 -->
		<Modal v-model="payModal" title="扫码支付二维码" :footer-hide="true" width="250" @on-cancel="colsePayModal()">
			<div style="text-align: center; width: 100%; font-weight: bold;">微信支付</div>
			<vue-qr :text="code_url" :size="220"></vue-qr>
		</Modal>
	</div>
</template>

<script>
	import vueQr from 'vue-qr'
	export default {
		name: "ChatAiOrderList",
		components: {
			vueQr
		},
		data() {
			return {
				formData: {
					num1: 0,
					num2: 0
				},
				search: {
					page: 1,
					total: 0,
					pageSize: 15
				},
				loading: false,
				statusArr: ['待支付', '已完成', '已取消'],
				columns: [{
						title: '订单号',
						key: 'orderid'
					},
					{
						title: '订单数量',
						key: 'amount'
					},
					{
						title: '订单金额',
						key: 'pay_money'
					},
					{
						title: '订单状态',
						key: 'status',
						render: (h, params) => {
							return h('span', this.statusArr[params.row.status])
						}
					},
					{
						title: '订单时间',
						key: 'create_date'
					}
				],
				data: [],
				// 购买套餐弹窗
				buy_package_modal: {
					show: false,
					list: [],
					data: {
						id: 0,
						name: '',
						price: 0,
						amount: 0,
					}
				},
				// 支付二维码 弹窗
				payModal: false,
				code_url: '',
				interval: null
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				const _this = this;
				this.loading = true;
				this.requestApi('/adm/get_ai_package_statistics', {
					search: this.search
				}).then((res) => {
					if (parseInt(res.status) === 1) {
						// 总数统计
						_this.formData = res.statistics;
						// 订单列表
						_this.loading = false;
						_this.data = res.order_list.data;
						_this.search.total = res.order_list.total;
					}
				})
			},
			// 打开 购买套餐 弹窗
			openBuyPackage() {
				const _this = this;
				this.requestApi('/adm/get_ai_package_list').then((res) => {
					if (res.status === 1) {
						_this.buy_package_modal.list = res.data;
						_this.buy_package_modal.show = true;
					}
				})
			},
			// 取消 购买套餐 弹窗
			colseBuyPackage() {
				this.buy_package_modal.show = false;
				this.buy_package_modal.list = [];
				this.buy_package_modal.data = {
					id: 0,
					name: '',
					price: 0,
					amount: 0,
				}
			},
			// 确定 购买套餐 弹窗
			confirmBuyPackage() {
				const _this = this;
				this.requestApi('/adm/cloud_get_ai_package_order', {
					id: this.buy_package_modal.data.id
				}).then((res) => {
					if (res.code === 200) {
						_this.code_url = res.data.code_url;
						// 打开微信支付二维码弹窗
						_this.payModal = true;
						// 定时获取订单状态
						_this.interval = setInterval(() => {
							_this.requestApi('/adm/get_ai_package_order_status', {
								order_id: res.data.order_number
							}).then((res) => {
								if (res.data !== 1) {
									_this.alertSucc('支付成功');
									// 关闭 支付二维码 弹窗
									_this.colsePayModal();
									// 关闭 购买套餐 弹窗
									_this.colse();
									setTimeout(() => {
										_this.$emit('on-change');
									}, 100)
								}
							})
						}, 1000)
					} else {
						_this.alertErr(res.msg);
					}
				})
			},
			activePackage(data) {
				this.buy_package_modal.data = data;
			},
			// 切换页码
			pageChange(page) {
				this.search.page = page
				this.get_order_list()
			},
			// 切换每页条数
			pageSizeChange(pageSize) {
				this.search.page = 1
				this.search.pageSize = pageSize
				this.get_order_list()
			},
			// 关闭 支付 弹窗
			colsePayModal() {
				// 关闭定时器
				clearInterval(this.interval);
				// 关闭扫码弹窗
				this.payModal = false;
				this.code_url = '';
				this.interval = null;
			}
		}
	}
</script>

<style scoped>
	.head {
		padding: 24px;
		border-radius: 4px;
		background-color: #FFFFFF;
		margin-bottom: 12px;
	}

	.head-label {
		margin-bottom: 12px;
	}

	.head-item {
		display: inline-block;
		width: 240px;
		padding-left: 24px;
	}

	.item-title {
		color: #999999;
		font-size: 13px;
	}

	.item-amount {
		color: #ed4014;
		font-size: 18px;
		font-weight: bold;
	}

	.pay-type {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 12px;
	}

	.pay-item {
		display: inline-flex;
		align-items: center;
		padding: 7px 20px;
		border: 1px solid #ed4014;
		border-radius: 3px;
		position: relative;
	}

	.pay-label {
		margin: 12px 0;
		font-size: 14px;
		font-weight: bold;
	}

	.tips {
		margin-top: 8px;
	}

	.item-box {
		border: 1px solid #eee;
		border-radius: 4px;
		padding: 8px 16px;
	}

	.item-info {
		display: flex;
		justify-content: space-between;
		font-size: 16px;
	}

	.item-price {
		margin-top: 6px;
		font-size: 24px;
		color: red;
	}

	.active {
		border-color: rgb(24, 144, 255);
	}
</style>